import React, { useEffect, useRef, useState } from "react"

// Icons import
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md"

import ScrollContainer, { ScrollEvent } from "react-indiana-drag-scroll"

// Arrow image import
// @ts-ignore
import Arrow from "../../assets/arrows/sendButtonArrow.svg"
import { FaPause, FaPlay } from "react-icons/fa"
import { PortfolioPhotosProps } from "./PortfolioPhotos"
import { useTheme } from "../ThemeProvider/ThemeProvider"
import { Link } from "gatsby"
import ArrowIcon from "../PortfolioCommercial/ArrowIcon"

interface WidthState {
	desired: number
	diff: number
	forceSmall: boolean
}

export const PortfolioPhotosDesktop: React.FC<PortfolioPhotosProps> = ({
	children,
	title,
	links,
	innerHTML,
	widthCallback,
}) => {
	const { colorMode } = useTheme()
	const [coverOpacity, setCoverOpacity] = useState(0)

	const [widthState, setWidthState] = useState<WidthState>({
		desired: window.innerWidth,
		diff: 0,
		forceSmall: false,
	})

	const scrollContainerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		scrollContainerRef.current?.addEventListener(
			"scroll",
			// @ts-ignore
			(e: React.UIEvent<HTMLDivElement>) => {
				const visibleWidth = e.currentTarget?.clientWidth
				const totalWidth = e.currentTarget.scrollWidth
				const scrolled = e.currentTarget.scrollLeft
				const amountScrolled = scrolled / (totalWidth - visibleWidth)

				setCoverOpacity(amountScrolled * 0.6)
			}
		)
	}, [scrollContainerRef])

	// We want to make the playing window smaller as the window height decreases.
	// This code makes sure it fits.
	useEffect(() => {
		function updateWidthState() {
			const desiredHeight = window.innerHeight - 90
			const maximumAllowedWidth = window.innerWidth * (1 - 2 * 0.07)
			const desired = Math.min(
				(desiredHeight / 9) * 16 - 80,
				maximumAllowedWidth
			)

			const diff = window.innerWidth - desired

			const forceSmall = window.innerHeight < 850

			setWidthState({
				forceSmall,
				desired,
				diff,
			})
		}

		updateWidthState()

		window.addEventListener("resize", updateWidthState)

		return () => window.removeEventListener("resize", updateWidthState)
	}, [])

	useEffect(() => {
		widthCallback(widthState.desired, widthState.forceSmall)
	}, [widthState])

	return (
		<div
			className="flex flex-row justify-between mt-[90px] relative"
			style={{
				width: widthState.forceSmall ? widthState.desired : "100%",
			}}
		>
			<div className="fixed flex justify-center items-center w-[7%] h-screen">
				<Link to={links.back}>
					<ArrowIcon color={colorMode === "light" ? "bg-black" : "bg-white"} />
				</Link>
			</div>

			<div className="fixed flex justify-center items-center right-0 w-[7%] h-screen">
				<Link to={links.forward}>
					<ArrowIcon
						right
						color={colorMode === "light" ? "bg-black" : "bg-white"}
					/>
				</Link>
			</div>

			<div
				className={`z-40 fixed top-[90px] aspect-video duration-75 snappingScroll`}
				style={{
					left: widthState.forceSmall ? widthState.diff / 2 : "7%",
					right: widthState.forceSmall ? widthState.diff / 2 : "7%",
				}}
			>
				<ScrollContainer
					horizontal={true}
					vertical={false}
					hideScrollbars={false}
					className="w-[100%] aspect-video"
					innerRef={scrollContainerRef}
				>
					<div className="flex flex-row w-[150%] h-[100%] mr-96">
						<div className="h-[80%] w-[30%] flex flex-row items-end snap-start">
							<div className="flex flex-row items-center justify-center">
								<div className="ml-16 text-white w-max mr-5 text-l xl:text-xl 3xl:text-2xl font-syne">
									Drag left for details
								</div>
								<img className="h-2" src={Arrow} alt="arrow pointing right" />
							</div>
						</div>
						<div className="h-[85%] w-[40%] flex flex-col justify-end items-center text-white">
							<div className="text-white text-l xl:text-xl 3xl:text-2xl font-syne">
								Scroll for next photo
							</div>
							<img
								className="mt-6 h-2 rotate-90"
								src={Arrow}
								alt="arrow pointing down"
							/>
						</div>
						<div className="flex justify-end items-center w-[30%] h-[95%]">
							<p
								className={`font-syne w-max h-[95%] ${widthState.forceSmall ? "text-2xl" : "text-4xl"
									} xl:text-5xl font-bold text-white rotate-180`}
								style={{ writingMode: "vertical-rl" }}
							>
								{title}
							</p>
						</div>
						<div className="w-[16%]"></div>
						<div className="text-white w-[40%] h-[90%] flex flex-col justify-end items-start text-xl snap-end">
							<div
								className="w-[90%]"
								dangerouslySetInnerHTML={
									innerHTML ? { __html: innerHTML } : undefined
								}
							>
								Sisterhood リモート撮影 - リモート写真撮影
								<br />	<br />
								日本のクライアントのために、ヨーロッパ圏でのリモート撮影を行なっております。撮影中は、リアルタイムで撮影状況を確認頂けるので、情報を共有する事ができ、遠隔でも立会いが可能になります。現地に行かずに、ヨーロッパ圏のモデルを幅広く選択する事ができ、撮影を実施する事が可能な為、コストパフォーマンスに優れています。

							</div>
						</div>
					</div>
				</ScrollContainer>
			</div>

			<div
				style={{
					opacity: coverOpacity,
					left: widthState.forceSmall ? widthState.diff / 2 : "7%",
					right: widthState.forceSmall ? widthState.diff / 2 : "7%",
				}}
				className={`fixed top-[90px] bottom-0 z-30 bg-black`}
			/>
		</div>
	)
}
