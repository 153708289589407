import React, { useState, useEffect } from "react"
import Navbar from "../../../components/Navbar/Navbar"
import i18n from "i18next"
import Footer from "../../../components/Footer/Footer"
import PortfolioPhotos from "../../../components/PortfolioPhotos/PortfolioPhotos"

import useMediaQuery from "../../../hooks/useMediaQuery"
import SwipeableLink from "../../../components/SwipeableLink"

const lamborghiniCommercial = () => {
	const showDesktop = useMediaQuery("(min-width: 1024px)")

	const [width, setWidth] = useState(1000 /*window.innerWidth*/)
	const [forceSmall, setForceSmall] = useState(false)
	useEffect(() => {
		i18n.changeLanguage("jp")
		  },
	[]);
	console.log(width, forceSmall)
	return (
		<SwipeableLink left="/jp/portfolio/tastemade" right="/jp/portfolio/zen">
			<div className="bg-tofuLightGrey h-full">
				<Navbar />
				<div className="w-[100%]  h-auto  items-center flex-col flex">
					<PortfolioPhotos
						mobileCoverPicUrl=""
						title="Sisterhoodie remote photo shoot"
						links={{
							forward: "/jp/portfolio/tastemade",
							back: "/jp/portfolio/zen",
						}}
						widthCallback={(nw, fs) => {
							setWidth(nw)
							setForceSmall(fs)
						}}
					></PortfolioPhotos>
					<div style={{ width: forceSmall ? width : "86%" }}>
						<Image
							src={
								"https://res.cloudinary.com/tofu-media/image/upload/c_scale,q_auto:eco,w_1800/v1656756011/sisterhoodie/2_koqq46.webp"
							}
						/>
						<Image
							src={
								"https://res.cloudinary.com/tofu-media/image/upload/c_scale,q_auto:eco,w_1800/v1656756013/sisterhoodie/3_duwmzy.webp"
							}
						/>
						<Image
							src={
								"https://res.cloudinary.com/tofu-media/image/upload/c_scale,q_auto:eco,w_1800/v1656756017/sisterhoodie/4_o9ztyu.webp"
							}
						/>
						<Image
							margin={showDesktop ? "12" : "0"}
							src={
								"https://res.cloudinary.com/tofu-media/image/upload/c_scale,q_auto:eco,w_1800/v1656756017/sisterhoodie/5_sh9brs.webp"
							}
						/>
					</div>
				</div>

				{!showDesktop && <Footer />}
			</div>
		</SwipeableLink>
	)
}

const Image: React.FC<{ src: string; margin?: string }> = ({
	src,
	margin = "10",
}) => <img src={src} alt="" className={`mb-${margin}`} />

export default lamborghiniCommercial
