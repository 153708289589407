import React, { ReactNode } from "react"
import useMediaQuery from "../../hooks/useMediaQuery"
import { PortfolioPhotosMobile } from "./mobile"
import { PortfolioPhotosDesktop } from "./desktop"

export type PortfolioPhotosProps = React.PropsWithChildren<{
	mobileCoverPicUrl: string
	title: string
	links: { back: string; forward: string }
	innerHTML?: string
	widthCallback: (newWidth: number, forceSmall: boolean) => void
}>

const PortfolioCommercial: React.FC<PortfolioPhotosProps> = (props) => {
	const showDesktop = useMediaQuery("(min-width: 1024px)")

	return (
		<div className="font-syne w-[100%]">
			{showDesktop ? (
				<PortfolioPhotosDesktop {...props} />
			) : (
				<PortfolioPhotosMobile {...props} />
			)}
		</div>
	)
}

export default PortfolioCommercial
