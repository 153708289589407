import React, { useRef, useState } from "react"

// Icons import
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md"

// Arrow image import
// @ts-ignore
import Arrow from "../../assets/arrows/arrowWhite.png"
import { FaPause, FaPlay } from "react-icons/fa"
import { useTheme } from "../ThemeProvider/ThemeProvider"
import { PortfolioPhotosProps } from "./PortfolioPhotos"
import { Link } from "gatsby"
import i18n from "../../i18n"

export const PortfolioPhotosMobile: React.FC<PortfolioPhotosProps> = ({
	children,
	title,
	links,
	innerHTML,
	mobileCoverPicUrl,
}) => {
	const { colorMode } = useTheme()

	return (
		<div className="flex flex-col justify-between w-[90%] mx-auto mt-24 bg-tofuLightGrey">
			<div className="flex flex-row justify-evenly align-center mb-6">
				<div className="flex justify-center items-center">
					<Link hrefLang={i18n.language === "en" ? "en" : "jp"}  to={links.back}>
						<MdArrowBackIos
							size={24}
							color={colorMode === "light" ? "black" : "white"}
						/>
					</Link>
				</div>

				<div className="flex justify-center items-center">
					<Link hrefLang={i18n.language === "en" ? "en" : "jp"}  to={links.forward}>
						<MdArrowForwardIos
							size={24}
							color={colorMode === "light" ? "black" : "white"}
						/>
					</Link>
				</div>
			</div>

			{mobileCoverPicUrl && (
				<div className="w-[100%] mb-10">
					<img className="w-full" src={mobileCoverPicUrl} alt="cover picture" />
				</div>
			)}

			<div className="flex flex-col justify-center align-center text-tofuLightWhite">
				<p className="font-syne text-2xl font-semibold leading-7 mb-5">
					{title}
				</p>
				<div className="w-[16%]"></div>
				<div
					className="leading-5"
					dangerouslySetInnerHTML={
						innerHTML ? { __html: innerHTML } : undefined
					}
				>
					Sisterhood リモート撮影 - リモート写真撮影
					<br />	<br />
					日本のクライアントのために、ヨーロッパ圏でのリモート撮影を行なっております。撮影中は、リアルタイムで撮影状況を確認頂けるので、情報を共有する事ができ、遠隔でも立会いが可能になります。現地に行かずに、ヨーロッパ圏のモデルを幅広く選択する事ができ、撮影を実施する事が可能な為、コストパフォーマンスに優れています。

				</div>
			</div>
		</div>
	)
}
